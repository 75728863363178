import { css } from "@emotion/css";
import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import { useSearchParams } from "react-router-dom";
import InDialog from "src/ui/components/InDialog/InDialog";
import useGoToOrBack from "src/ui/hooks/useGoToOrBack";
import { AppQueryPopupsController } from "../AppQueryPopupsBloc";

const ionModalStyles = css`
  // popup options for iframe
  ion-content {
    &::part(scroll) {
      overflow: hidden;
      padding: 0;
    }
  }
`;

const DetailFrame = styled.iframe`
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  margin: 0;
  padding-bottom: 0 !important;
`;

const IframePopup: FC<{
  returnUrl: string;
}> = ({ returnUrl }) => {
  const navigate = useGoToOrBack();

  const [query] = useSearchParams();
  const properUrl = decodeURIComponent(query.get("url") ?? "");
  const title = query.get("title") ?? "";
  const isArticle = query.get("popup") === "article";

  const onClose = () => {
    AppQueryPopupsController.closePopup();
    navigate(returnUrl, {
      multiBack: true,
      replace: true
    });
  };

  return (
    <InDialog
      popup
      title={title}
      onClose={onClose}
      returnUrl={returnUrl}
      className={ionModalStyles}
      isFullScreen={isArticle}
    >
      {properUrl && <DetailFrame src={properUrl} />}
    </InDialog>
  );
};

export default IframePopup;
